










import Vue from "vue";
import moment from "moment";
import { Steps, Step } from "vant";
moment.locale("zh-cn");

export default Vue.extend({
  components: {
    [Steps.name]: Steps,
    [Step.name]: Step
  },
  data() {
    return {
      list: [] as string[],
      box2Id: this.$route.query.box2Id || 0,
      no: this.$route.query.no || 0,
      startTime: this.$route.query.startTime || 0,
      endTime: this.$route.query.endTime || 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const data = {
        box2Id: this.box2Id,
        no: this.no,
        startTime: this.startTime,
        endTime: this.endTime
      };
      this.$axios.post("/api/manage/getRoomStep", data).then(res => {
        this.list = res.data.data.reverse();
      });
    },
    formatTime(time: number) {
      return moment(time).format("LL LTS");
    }
  }
});
